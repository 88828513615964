
	import HTTP from "@utils/http";
	// 素质拓展课
	export async function stucurriculumcollectionList(prams) {
		try {
		return await HTTP.get("/studentpc/stucurriculumcollection/list", prams);
		} catch (error) {
		return error;
		}
	}
	// 取消收藏
	export async function stucurriculumcollectionDelete(prams) {
		try {
		return await HTTP.post("/studentpc/stucurriculumcollection/delete", prams);
		} catch (error) {
		return error;
		}
	}
	
	// 个人信息
	export async function stuuserInfo(prams) {
		try {
		return await HTTP.post("/studentpc/stuuser/info", prams);
		} catch (error) {
		return error;
		}
	}
	// 个人信息
	export async function check_pwd(prams) {
		try {
		return await HTTP.post("/studentpc/stuuser/check_pwd", prams);
		} catch (error) {
		return error;
		}
	}
	
	// 修改登录密码
	export async function update_pwd(prams) {
		try {
		return await HTTP.post("/studentpc/stuuser/update_pwd", prams);
		} catch (error) {
		return error;
		}
	}
	
	// 意见反馈
	export async function plafeedbackAdd(prams) {
		try {
		return await HTTP.post("/studentpc/plafeedback/add", prams);
		} catch (error) {
		return error;
		}
	}
	
	// 消息中心列表
	export async function plastumessageList(prams) {
		try {
		return await HTTP.post("/studentpc/plastumessage/list", prams);
		} catch (error) {
		return error;
		}
	}
	
	// 消息详情
	export async function plastumessageInfo(prams) {
		try {
		return await HTTP.post("/studentpc/plastumessage/info", prams);
		} catch (error) {
		return error;
		}
	}
	
	// 家长列表
	export async function paruserstuserList(prams) {
		try {
		return await HTTP.post("/studentpc/paruserstuser/list", prams);
		} catch (error) {
		return error;
		}
	}
	
	// 同意/拒绝绑定
	export async function paruserstuserAgree_refuse(prams) {
		try {
		return await HTTP.post("/studentpc/paruserstuser/agree_refuse", prams);
		} catch (error) {
		return error;
		}
	}
	
	// 修改家长备注
	export async function paruserstuserEdit_remarks(prams) {
		try {
		return await HTTP.post("/studentpc/paruserstuser/edit_remarks", prams);
		} catch (error) {
		return error;
		}
	}
	
	// 证书列表
	export async function stuusercertificateList(prams) {
		try {
		return await HTTP.post("/studentpc/stuusercertificate/list", prams);
		} catch (error) {
		return error;
		}
	}
	// 证书详情
	export async function stuusercertificateInfo(prams) {
		try {
		return await HTTP.post("/studentpc/stuusercertificate/info", prams);
		} catch (error) {
		return error;
		}
	}
	
	
	// 证书模板
	export async function placertificatetemplateList(prams) {
		try {
		return await HTTP.post("/studentpc/placertificatetemplate/list", prams);
		} catch (error) {
		return error;
		}
	}
	
	// 添加证书
	export async function stuusercertificateAdd(prams) {
		try {
		return await HTTP.post("/studentpc/stuusercertificate/add", prams);
		} catch (error) {
		return error;
		}
	}
	// 删除证书
	export async function stuusercertificateDelete(prams) {
		try {
		return await HTTP.post("/studentpc/stuusercertificate/delete", prams);
		} catch (error) {
		return error;
		}
	}
	// 更换模板
	export async function stuusercertificateEdit(prams) {
		try {
		return await HTTP.post("/studentpc/stuusercertificate/edit", prams);
		} catch (error) {
		return error;
		}
	}
	// 我的设备列表
	export async function stuboardList(prams) {
		try {
		return await HTTP.post("/studentpc/stuboard/list", prams);
		} catch (error) {
		return error;
		}
	}
	// 绑定设备
	export async function stuboardBinding(prams) {
		try {
		return await HTTP.post("/studentpc/stuboard/binding", prams);
		} catch (error) {
		return error;
		}
	}
	// 解除绑定
	export async function stuboardRemove_binding(prams) {
		try {
		return await HTTP.post("/studentpc/stuboard/remove_binding", prams);
		} catch (error) {
		return error;
		}
	}
	// 修改设备名称
	export async function update_board_name(prams) {
		try {
		return await HTTP.post("/studentpc/stuboard/update_board_name", prams);
		} catch (error) {
		return error;
		}
	}
	// 修改头像
	export async function update_image(prams) {
		try {
		return await HTTP.post("/studentpc/stuuser/update_image", prams);
		} catch (error) {
		return error;
		}
	}
	// 注销账号
	export async function logoff_account(prams) {
		try {
		return await HTTP.post("/studentpc/stuuser/logoff_account", prams);
		} catch (error) {
		return error;
		}
	}
