<!--
 * @Author: Dyf
 * @Date: 2023-02-01
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-11-20 09:31:41
 * @Description: 
-->
<style lang="scss" scoped>
.app-main {
  width: calc(100% - 220px);
  height: calc(100% - 100px);
  margin-left: 170px;
  &.ishome {
    width: 100%;
    height: 100%;
    margin-left: 0;
  }
  .table-box {
    z-index: 0;
    position: relative;
  }
}

@media screen and (max-width: 1200px) {
  .app-main {
    width: calc(100% - 160px);
    height: calc(100% - 80px);
    margin-left: 140px;
  }
}
</style>

<template>
  <section class="app-main" :class="{ ishome: isHome }">
    <!-- 需要缓存的页面 -->
    <keep-alive>
      <router-view :key="key" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <!-- 不用缓存的页面 -->
    <router-view :key="key" v-if="!$route.meta.keepAlive" />
  </section>
</template>

<script>
export default {
  name: "AppMain",
  computed: {
    key() {
      return this.$route.path;
    },
  },
  watch: {
    $route: {
      handler(route) {
        let list = route.matched;
        this.getBreadcrumb(list);
      },
      immediate: true,
    },
  },
  data() {
    return {
      isHome: true,
    };
  },
  created() {},
  methods: {
    /** 判断是否是首页 */
    pageHome(route) {
      return route.name == "HOME" || route.name == "HOME_INDEX";
    },
    /** 获取面包屑数据 */
    getBreadcrumb(matched) {
      matched = this.$_.unionBy(matched, "meta.title");
      /* 如果不是首页 */
      if (!this.pageHome(matched[matched.length - 1])) {
        matched = [].concat(matched);
        this.isHome = false;
      } else {
        this.isHome = true;
      }
    },
  },
};
</script>
