/*
 * @Author: Dyf
 * @Date: 2023-11-07 14:53:58
 * @LastEditors: 
 * @LastEditTime: 2023-11-07 14:54:54
 * @Description: 校园动态模块路由
 */
import Layout from "@views/layout"; // 布局

export default {
    path: "/schoolNews",
    component: Layout,
    name: "SCHOOLNEWS",
    meta: { title: "校园动态" },
    redirect: { name: "SCHOOLNEWS_INDEX" },
    children: [
        {
            path: "index",
            name: "SCHOOLNEWS_INDEX",
            meta: { title: "校园动态" },
            component: () => import("@views/schoolNews/index"),
        },
        {
            path: "detail/:id",
            name: "SCHOOLNEWS_DETAIL",
            meta: { title: "详情" },
            component: () => import("@views/schoolNews/detail"),
        }
    ],
};
