/*
 * @Author: Dyf
 * @Date: 2023-02-18 18:25:27
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-11-07 14:52:18
 * @Description: 首页模块路由
 */
import Layout from "@views/layout"; // 布局

const homeRouter = {
  path: "/",
  component: Layout,
  name: "HOME",
  meta: { title: "首页" },
  redirect: { name: "HOME_INDEX" },
  children: [
    {
      path: "home",
      name: "HOME_INDEX",
      meta: { title: "首页" },
      component: () => import("@views/home/index"),
    },
    {
      path: "home/personalCenter",
      name: "HOME_PERSONALCENTER",
      meta: { title: "个人中心" },
      component: () => import("@views/home/personalCenter"),
    },
    {
      path: "home/notify",
      name: "HOME_NOTIFY",
      meta: { title: "通知公告" },
      component: () => import("@views/home/notify"),
    },
    {
      path: "home/notify/:id",
      name: "HOME_NOTIFYDETAIL",
      meta: { title: "动态详情" },
      component: () => import("@views/home/notifyDetail"),
    },
    {
      path: "home/message",
      name: "HOME_MESSAGE",
      meta: { title: "消息中心" },
      component: () => import("@views/home/message"),
    },
    {
      path: "home/notify/:id",
      name: "HOME_NOTIFYDETAIL1",
      meta: { title: "消息详情" },
      component: () => import("@views/home/notifyDetail1"),
    },
    {
      path: "home/notify/:id",
      name: "HOME_NOTIFYDETAIL2",
      meta: { title: "消息详情" },
      component: () => import("@views/home/notifyDetail2"),
    },
    {
      path: "home/notify/:url",
      name: "HOME_NOTIFYDETAIL3",
      meta: { title: "消息详情" },
      component: () => import("@views/home/notifyDetail3"),
    },
    {
      path: "home/updataAudit",
      name: "UPDATA_AUDIT",
      meta: { title: "修改审核信息" },
      component: () => import("@views/home/updataAudit"),
    },
  ],
};

export default homeRouter;
