import { Loading } from "element-ui";

let loading;
let loadingCount = 0; // 默认状态

/** 初始化loading事件 */
function startLoading(el = "") {
  loading = Loading.service({
    lock: true,
    text: "数据加载中...",
    spinner: "el-icon-loading",
    background: "rgb(255, 255, 255)",
    fullscreen: false,
    target: el,
  });
}

/** 关闭loading事件 */
function endLoading() {
  loading.close();
}

/** 开启loading */
export function showLoading(el) {
  if (loadingCount === 0) {
    startLoading(el);
  }
  loadingCount++;
}
/** 取消loading */
export function hideLoading() {
  if (loadingCount <= 0) return;
  loadingCount--;
  if (loadingCount === 0) {
    endLoading();
  }
}
