/**
 * @Author: Dyf
 * @Date: 2023-06-30 09:12
 * @LastEditors: Dyf
 * @LastEditTime: 2023-06-30 18:59
 * @Descripttion: 
 */
/*
 * @Descripttion: 请求方法封装
 * @Author: Dyf
 * @Date: 2023-04-12 09:23:59
 * @LastEditors: Dyf
 * @LastEditTime: 2023-04-12 09:37:56
 */
import request from "@utils/request";

/**
 * @param url 请求地址
 * @param params 请求参数
 * @param other 其他配置参数
 */
const http = {
  /* get */
  get(url, params, other = {}) {
    let config = publicFn("get", url, params, other);
    return request(config);
  },
  /* post */
  post(url, params, other = {}) {
    let config = publicFn("post", url, params, other);
    return request(config);
  },
  /* put */
  put(url, params, other = {}) {
    let config = publicFn("put", url, params, other);
    return request(config);
  },
  /* delete */
  delete(url, params, other = {}) {
    let config = publicFn("delete", url, params, other);
    return request(config);
  },
};

/* 公共方法体 */
function publicFn(method, url, params, other) {
  /* load：数据加载  loadEl：加载相对父级元素 */
  let otherParams = { load: false, loadEl: "", ...other };
  otherParams = {
    ...otherParams,
    load: otherParams.load,
    loadEl:
      (otherParams.load && otherParams.loadEl) ||
      (otherParams.load && ".table-inner") ||
      "",
  };
  const config = {
    method,
    url,
    ...otherParams,
  };
  if (params && method == "get") config.params = params;
  else if (params) config.data = params;
  return config;
}

export default http;
