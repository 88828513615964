/*
 * @Author: Dyf
 * @Date: 2023-02-18 18:25:27
 * @LastEditors: Dyf
 * @LastEditTime: 2023-04-11 09:33:00
 * @Description: 综合分析模块路由
 */
import Layout from "@views/layout"; // 布局

const interactionRouter = {
  path: "/interaction",
  component: Layout,
  name: "INTERACTION_MESSAGE",
  meta: { title: "互动中心" },
  redirect: { name: "INTERACTION_INDEX" },
  children: [
    {
      path: "",
      name: "INTERACTION_INDEX",
      meta: { title: "互动中心" },
      component: () => import("@views/interaction/index"),
    },
    {
      path: "message",
      name: "INTERACTION_MESSAGE",
      meta: { title: "联系老师" },
      component: () => import("@views/interaction/message"),
    },
  ],
};
export default interactionRouter;
