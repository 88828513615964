
import Layout from "@views/layout"; // 布局

export default {
  path: "/mistakes",
  component: Layout,
  name: "MISTAKES",
  meta: { title: "错题集" },
  redirect: { name: "MISTAKES_INDEX" },
  children: [
    {
      path: "mistakesIndex",
      name: "MISTAKES_INDEX",
      meta: { title: "错题集" },
      component: () => import("@views/mistakes/index"),
    },
    {
      path: "mistakesDetails/:id/:course/:sccla_id",
      name: "MISTAKES_DETAILS",
      meta: { title: "科目错题" },
      component: () => import("@views/mistakes/mistakesDetails"),
    },
    {
      path: "doExercise/:id/:random_num/:sccla_id",
      name: "MISTAKES_DOEXERCISE",
      meta: { title: "答题详情" },
      component: () => import("@views/mistakes/doExercise"),
    },
    {
      path: "questionsDetails/:id",
      name: "MISTAKES_QUESTIONS",
      meta: { title: "答题详情" },
      component: () => import("@views/mistakes/questionsDetails"),
    },
   
  ],
};
