
import Layout from "@views/layout"; // 布局

export default {
  path: "/jobExam",
  component: Layout,
  name: "JOBEXAM",
  meta: { title: "作业考试" },
  redirect: { name: "EXAM_INDEX" },
  children: [
    {
      path: "examIndex",
      name: "EXAM_INDEX",
      meta: { title: "考试" },
      component: () => import("@views/jobExam/examIndex"),
    },
    {
      path: "examinationFinish/:id",
      name: "EXAM_INATION_FINISH",
      meta: { title: "试题详情" },
      component: () => import("@views/jobExam/examinationFinish"),
    },
    {
      path: "examinationPaper/:id",
      name: "EXAM_INATION_PAPER",
      meta: { title: "试题详情" },
      component: () => import("@views/jobExam/examinationPaper"),
    },
    {
      path: "examinationDetailsItem/:index1/:index2/:id",
      name: "EXAM_INATION_DETAILS_ITEM",
      meta: { title: "试题详情" },
      component: () => import("@views/jobExam/examinationDetailsItem"),
    },
    {
      path: "examinationDetails/:id/:endtime/:make_type/:paper_url",
      name: "EXAM_INATION_DETAILS",
      meta: { title: "试题详情" },
      component: () => import("@views/jobExam/examinationDetails"),
    },
    {
      path: "jobIndex",
      name: "JOB_INDEX",
      meta: { title: "作业" },
      component: () => import("@views/jobExam/jobIndex"),
    },
    {
      path: "schoolJobIndex",
      name: "SCHOOL_JOB_INDEX",
      meta: { title: "作业" },
      component: () => import("@views/jobExam/schoolJobIndex"),
    },
    {
      path: "jobDetails/:id/:school/:accomplish",
      name: "JOB_DETAILS",
      meta: { title: "作业详情" },
      component: () => import("@views/course/jobDetails"),
    },
    {
      path: "jobDetailsNot/:id/:type",
      name: "JOB_DETAILS_NOT",
      meta: { title: "作业详情" },
      component: () => import("@views/course/jobDetailsNot"),
    },
    {
      path: "jobAccomplish/:id/",
      name: "JOB_ACCOMP_LISH",
      meta: { title: "试题详情" },
      component: () => import("@views/course/jobAccomplish"),
    },
    {
      path: "jobDetailsItem/:index/:id/:sthom_wor_answer_type",
      name: "JOB_DETAILS_ITEM",
      meta: { title: "作业详情" },
      component: () => import("@views/course/jobDetailsItem"),
    }
  ],
};
