<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-22 18:27:30
 * @LastEditTime: 2023-04-10 15:26:42
 * @Descripttion: 
-->
<template>
    <div class="breadcrumb light">
        <p style="margin-right: 8px">当前位置:</p>
        <el-breadcrumb separator=">">
            <el-breadcrumb-item v-for="(item, index) in breadList" :key="index">
                {{ item.meta.title }}
            </el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>

<script>
export default {
    name: "breadcrumb",
    data() {
        return {
            breadList: [],
        };
    },
    watch: {
        $route: {
            handler(route) {
                let list = route.matched;
                this.getBreadcrumb(list);
            },
            immediate: true,
        },
    },
    created() { },
    methods: {
        /** 判断是否是首页 */
        pageHome(route) {
            return route.name == "HOME";
        },
        /** 获取面包屑数据 */
        getBreadcrumb(matched) {
            matched = this.$_.uniqWith(matched, "meta.title");
            matched = matched.filter(item => item.meta.title);
            /* 如果不是首页或者是首页子页面 */
            if (!this.pageHome(matched[0]) || matched.length > 1) {
                matched = [].concat(matched);
            }
            this.breadList = matched;
            let index = matched.length - 1;
            if (matched[index].meta.insertClass) {
                let title = matched[index].meta.title.split('>');
                title.splice(1, 1, this.$route.params.class);
                if (matched[index].meta.pTitle) {
                    title.splice(0, 0, matched[index].meta.pTitle);
                }
                title.splice(1, 0, this.$route.params.class);
                title = this.$_.uniq(title);
                this.breadList[index].meta.title = title.join('>');
            }
            if (this.breadList[index].meta.title.split('>')[0] == this.breadList[index - 1].meta.title.split('>')[0]) {
                this.$_.pullAt(this.breadList, index - 1);
            }
        },
    },
};
</script>