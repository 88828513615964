
import Layout from "@views/layout"; // 布局

export default {
  path: "/course",
  component: Layout,
  name: "COURSE",
  meta: { title: "校内课程" },
  redirect: { name: "COURSE_INDEX" },
  children: [
    {
      path: "index",
      name: "COURSE_INDEX",
      meta: { title: "校内课程" },
      component: () => import("@views/course/index"),
    },
    {
      path: "schoolPlay/:id",
      name: "SCHOOL_PLAY",
      meta: { title: "课堂回放详情" },
      component: () => import("@views/course/schoolPlay"),
    },
    {
      path: "keyPoint/:id",
      name: "KEY_POINT",
      meta: { title: "知识点 " },
      component: () => import("@views/course/keyPoint"),
    },
    {
      path: "interaction/:id",
      name: "INTERACTION",
      meta: { title: "课堂互动 " },
      component: () => import("@views/course/interaction"),
    },
    {
      path: "questionsDetails/:index/:id",
      name: "QUESTIONS_DETAILS",
      meta: { title: "答题详情" },
      component: () => import("@views/course/questionsDetails"),
    },
    {
      path: "jobDetails/:id/:school/:accomplish",
      name: "COURSE_DETAILS",
      meta: { title: "作业详情" },
      component: () => import("@views/course/jobDetails"),
    },
    {
      path: "jobDetailsNot/:id/:type",
      name: "COURSE_DETAILS_NOT",
      meta: { title: "作业详情" },
      component: () => import("@views/course/jobDetailsNot"),
    },
    {
      path: "jobDetailsItem/:index/:id/:sthom_wor_answer_type",
      name: "COURSE_DETAILS_ITEM",
      meta: { title: "作业详情" },
      component: () => import("@views/course/jobDetailsItem"),
    }
  ],
};
