/*
 * @Author: Dyf
 * @Date: 2023-11-07 14:11:43
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-11-13 15:05:12
 * @Description: 素质教育模块路由
 */
import Layout from "@views/layout"; // 布局

export default {
    path: "/quality",
    component: Layout,
    name: "QUALITY",
    meta: { title: "素质教育" },
    redirect: { name: "QUALITY_EDUCATION" },
    children: [
        {
            path: "education",
            name: "QUALITY_EDUCATION",
            meta: { title: "素质课程" },
            component: () => import("@views/qualityEducation/index"),
        },
        {
            path: "mine",
            name: "QUALITY_MINE",
            meta: { title: "我的素质课程" },
            component: () => import("@views/qualityEducation/mine"),
        },
        {
            path: "education/detail/:id",
            name: "QUALITY_EDUCATION_DETAIL",
            meta: { title: "素质课程>详情" },
            component: () => import("@views/qualityEducation/detail"),
        }, {
            path: "education/study",
            name: "QUALITY_EDUCATION_STUDY",
            meta: { title: "素质课程>学习" },
            component: () => import("@views/qualityEducation/study"),
        },
        {
            path: "mine/detail/:id",
            name: "QUALITY_MINE_DETAIL",
            meta: { title: "我的素质课程>详情" },
            component: () => import("@views/qualityEducation/detail"),
        }, {
            path: "mine/study",
            name: "QUALITY_MINE_STUDY",
            meta: { title: "我的素质课程>学习" },
            component: () => import("@views/qualityEducation/study"),
        },
    ],
};
