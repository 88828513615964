/*
 * @Author: Dyf
 * @Date: 2023-02-01
 * @LastEditors: Dyf
 * @LastEditTime: 2023-03-29 14:35:47
 * @Description:
 */
import router from "./router";
import { Message } from "element-ui";
import getPageTitle from "@/utils/get-page-title";

const whiteList = ["/login"]; // 不需要重定向的白名单

router.beforeEach(async (to, from, next) => {
  let reg = /(http|https):\/\/([\w.]+\/?)\S*/;
  /* 校验是否是外部链接，是就直接跳转 */
  if (reg.test(to.path)) {
    let url = to.path.slice(reg.exec(to.path).index);
    return window.open(url, "_blank");
  }

  if (to.name == 404) {
    sessionStorage.removeItem("activeMenu");
  }
  if (to.matched.length == 0) {
    next({ path: "/404" });
  }
  // 设置页面标题
  let title = to.meta.title.split(">");
  document.title = getPageTitle(title[title.length - 1]);

  // 判断用户是否已经登录
  const hasToken = sessionStorage.getItem("token");
  // 判断是否需要修改密码
  const updatePwd = sessionStorage.getItem("updatePwd");
  if (hasToken) {
    //跳转去修改密码
    if(updatePwd&&to.path != "/home/personalCenter"){
      Message.warning("为了您的账户安全，请修改密码");
      next({ name: "HOME_PERSONALCENTER" });
    }else if (to.path === "/login") {
      // 如果已登录，则重定向到主页
      next({ name: "HOME" });
    } else {
      try {
        next();
      } catch (error) {
        // 删除令牌并转到登录页面重新登录
        sessionStorage.clear();
        Message.error(error || "有错误");
        next(`/login?redirect=${to.path}`);
      }
    }
  } else {
    /* 没有令牌*/
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免费登录白名单，直接去
      next();
    } else {
      // 其他没有访问权限的页面被重定向到登录页面。
      next(`/login?redirect=${to.path}`);
    }
  }
});

router.afterEach(() => {});
