/*
 * @Descripttion: 用户信息存储
 * @Author: Dyf
 * @Date: 2023-04-14
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-11-07 16:32:27
 */
import { stuuserInfo } from "@api/center";

const state = {
  userInfo: {}, // 用户信息
};

const mutations = {
  setStateNewValue: (state, obj) => {
    state[obj.key] = obj.value;
  },
};

const actions = {
  /** 用户信息 */
  async getUserInfo({ commit }) {
    let { data } = await stuuserInfo();
    sessionStorage.setItem("userId", data.tencent_im_account);
    await commit("setStateNewValue", {
      key: "userInfo",
      value: data,
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
