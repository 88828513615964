/*
 * @Author: Dyf
 * @Date: 2023-11-17 11:27:38
 * @LastEditors: 
 * @LastEditTime: 2023-11-17 11:29:49
 * @Description: 
 */
import Layout from "@views/layout"; // 布局

export default {
    path: "/schoolTimetable",
    component: Layout,
    name: "SCHOOLTIMETABLE",
    redirect: { name: "SCHOOLTIMETABLE_INDEX" },
    meta: { title: "课程表" },
    children: [
        {
            path: "index",
            name: "SCHOOLTIMETABLE_INDEX",
            meta: { title: "课程表" },
            component: () => import('@views/schoolTimetable/index'),
        },
        {
            path: "play/:id",
            name: "SCHOOLTIMETABLE_PLAY",
            meta: { title: "课程表>课程回放" },
            component: () => import('@views/schoolTimetable/play'),
        },
    ],
};