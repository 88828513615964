<!--
 * @Author: Dyf
 * @Date: 2023-02-01
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-10-30 14:13:12
 * @Description: -
-->

<style lang="scss">
@include scrollBar;
</style>

<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import imlogin from "@/views/interaction/chat/components/login";
export default {
  name: "App",
  
  created() {
    sessionStorage.removeItem("imIsSDKReady")
    //开启聊天
    imlogin.loginUser(this)
  },
};
</script>
