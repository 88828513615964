/*
 * @Author: Dyf
 * @Date: 2023-11-07 14:53:58
 * @LastEditors: 
 * @LastEditTime: 2023-11-07 14:55:09
 * @Description: 严选课程模块路由
 */
import Layout from "@views/layout"; // 布局

export default {
    path: "/strict",
    component: Layout,
    name: "STRICT",
    meta: { title: "严选课程" },
    redirect: { name: "STRICT_COURSE" },
    children: [
        {
            path: "course",
            name: "STRICT_COURSE",
            meta: { title: "严选课程" },
            component: () => import("@views/strictCourses/index"),
        },
        {
            path: "detail/:id",
            name: "STRICT_DETAIL",
            meta: { title: "详情" },
            component: () => import("@views/strictCourses/detail"),
        }
    ],
};
