

// 聊天秘钥加密
import {genTestUserSig} from '../debug/GenerateTestUserSig.js'
/** 
 * 
 * 登录im
 */
function loginUser(_this) {
	if(sessionStorage.getItem("userId")){
		let userID= sessionStorage.getItem("userId").toString()
		// im登录中就不需要再登录
		if(sessionStorage.getItem("imIsSDKReady")){
			return false
		}
		let userSig = genTestUserSig({
			SDKAppID: 1400814172,
			secretKey: "c97e1dec58e1c7f61c629a917584a86b6e716d60fa9afefb0a9284acd566dcc0",
			userID: userID
		}).userSig
		// 登录
		
		_this.tim.login({
			userID: userID,
			userSig: userSig
		}).then(function(imResponse) {
			console.log("im登录成功"); // 登录成功
			// if (imResponse.data.repeatLogin === true) {
			// 	// 标识帐号已登录，本次登录操作为重复登录。v2.5.1 起支持
			// 	// console.log("重复登录",imResponse.data.errorInfo);
			// }
			bindTIMEvent(_this)
		}).catch(function(imError) {
			console.warn('登录失败', imError); // 登录失败的相关信息
		})
	}else{
		setTimeout(() => {
			loginUser(_this)
		}, 5000);
	}
}

// 退出登录
function imlogout(_this){
	_this.tim.logout().then(function(imResponse) {
		sessionStorage.removeItem("imIsSDKReady")
	})	
}
//聊天驱动
function bindTIMEvent(_this){
	_this.tim.on(_this.TIM.EVENT.SDK_READY,()=>{
		sessionStorage.setItem("imIsSDKReady",true)
	});
	_this.tim.on(_this.TIM.EVENT.SDK_NOT_READY);
}
export default {
	loginUser,
	imlogout
}
