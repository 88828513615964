<!--
 * @Author: Dyf
 * @LastEditors: Please set LastEditors
 * @Date: 2023-02-24 10:32:32
 * @LastEditTime: 2023-11-20 09:31:58
 * @Descripttion: 
-->
<style lang="scss" scoped>
.navbar {
  width: calc(100% - 220px);
  height: 80px;
  margin-left: 170px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba($color: #404040, $alpha: 0.08);
  .breadcrumb {
    margin-right: auto;
    padding-top: 28px;
    font-size: 16px;
    height: 2em;
    color: #484848;
    line-height: 2em;
    @include flexBox;
  }
}

@media screen and (max-width: 1200px) {
  .navbar {
    width: calc(100% - 160px);
    margin-left: 140px;
  }
}
</style>

<template>
  <section class="navbar" v-if="!isHome">
    <div class="breadcrumb light">
      <p style="margin-right: 8px">当前位置:</p>
      <el-breadcrumb separator=">">
        <el-breadcrumb-item v-for="(item, index) in breadList" :key="index">
          {{ item.meta.title }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </section>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      isHome: true,
      breadList: [],
    };
  },
  watch: {
    $route: {
      handler(route) {
        let list = route.matched;
        this.getBreadcrumb(list);
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    /** 判断是否是首页 */
    pageHome(route) {
      return route.name == "HOME" || route.name == "HOME_INDEX";
    },
    /** 获取面包屑数据 */
    getBreadcrumb(matched) {
      matched = this.$_.unionBy(matched, "meta.title");
      /* 如果不是首页 */
      if (!this.pageHome(matched[matched.length - 1])) {
        matched = [].concat(matched);
        this.isHome = false;
      } else {
        this.isHome = true;
      }
      matched = this.$_.uniqBy(matched, "meta.title");
      matched = matched.filter((item) => item.meta.title);
      this.breadList = matched;
    },
  },
};
</script>
