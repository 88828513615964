<!--
 * @Author: Dyf
 * @Date: 2023-02-01
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-12-01 10:42:41
 * @Description: 左侧导航栏
-->
<style lang="scss" scoped>
.sidebar {
  width: 120px;
  height: 100%;
  background: #fff;
  border-radius: 0 30px 30px 0;
  box-sizing: border-box;
  @include flexBox;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;

  .user {
    width: 100%;
    flex-shrink: 0;
    @include flexBox;
    flex-direction: column;
    line-height: 2em;
    // height: calc(3.73vh + 124px);
    box-sizing: border-box;
    padding-top: 2.98vh;
    padding-bottom: 4vh;
    background: #fff;
    position: relative;
    z-index: 2;
    border-radius: 30px 30px 0 0;
    cursor: pointer;

    .avatar {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      position: relative;
      margin-bottom: 10px;

      &::after {
        content: "";
        width: 10px;
        height: 10px;
        background: #1fccb6;
        border-radius: 50%;
        position: absolute;
        bottom: 2px;
        right: 4px;
      }
    }

    .bold {
      box-sizing: border-box;
      padding: 0 20px;
      width: 100%;
      text-align: center;
    }
    .title {
      font-size: 14px;
      padding: 0 10px;
      line-height: 15px;
      color: #999999;
    }
    .state {
      border-radius: 5px;
      line-height: 15px;
      padding: 2px;
      color: #ffffff;
      font-size: 12px;
      background-color: #ff7c12;
      position: absolute;
      left: 50%;
      bottom: -1px;
      width: 100%;
      text-align: center;
      transform: translate(-50%, 0%);
      z-index: 50;
      &.fail {
        background-color: #ff4d4f;
      }
    }
    &::before {
      content: "";
      width: 68px;
      height: 1px;
      background: #dcdee8;
      position: absolute;
      bottom: 0.75vh;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .menu {
    width: 100%;
    height: calc(100% - 7vh - 150px);
    overflow: visible;

    i {
      font-family: "iconfont";
      font-style: normal;
      display: inline-block;
    }

    .swiper-wrapper {
      transition-timing-function: linear !important;
    }

    &-fir {
      width: 100%;
      height: 66px;
      padding: 1.4vh 0;
      position: relative;

      .title {
        flex-wrap: wrap;
        @include flexBox(center);
        @include defaultAni(0.8s);
        box-sizing: border-box;
        font-size: 16px;
        color: #3a3a3a;
        cursor: default;
        position: relative;

        .dian {
          position: absolute;
          right: 30px;
          top: 0px;
          height: 18px;
          line-height: 18px;
          background-color: #f66478;
          border-radius: 10px;
          color: #ffffff;
          font-size: 12px;
          padding: 0 6px;
        }

        i {
          display: inline-block;
          width: 26px;
          height: 26px;
          line-height: 26px;
          text-align: center;
          border-radius: 10px;
          font-size: 24px;
          color: #cbc4e1;
          border-radius: 50%;
        }

        p {
          width: 100%;
          color: #212121;
          font-size: 16px;
          text-align: center;
          line-height: 24px;
          margin-top: 6px;
        }

        &.link {
          cursor: pointer;
        }
      }

      &.current,
      &:hover {
        height: 76px;

        .title {
          color: #6c4ecb;

          i {
            background: #6c4ecb;
            color: #fff;
            width: 42px;
            height: 42px;
            line-height: 42px;
          }

          p {
            margin-top: 10px;
          }
        }
      }

      &:hover {
        .menu-sec-box {
          opacity: 1;
          z-index: 3;
          max-height: 100vh;
        }
      }

      &:nth-last-child(1) {
        .menu-sec-box {
          transform: translateY(-85%);

          &::after {
            top: 85%;
          }
        }
      }

      &:nth-last-child(2),
      &:nth-last-child(3) {
        .menu-sec-box {
          transform: translateY(-80%);

          &::after {
            top: 80%;
          }
        }
      }
    }

    &-sec-box {
      max-height: 0;
      overflow: hidden;
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
      box-sizing: border-box;
      padding-left: 18px;
      opacity: 0;
      z-index: 0;
      transition-duration: 0;

      &::after {
        content: "";
        border: solid;
        border-width: 6px 8px 6px 6px;
        border-color: transparent #6c4ecb transparent transparent;
        position: absolute;
        top: 50%;
        left: 4px;
        transform: translateY(-50%);
      }

      p,
      span,
      i {
        white-space: nowrap;
      }

      ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        border-radius: 2px;
      }

      ::-webkit-scrollbar-thumb {
        background: #af9bee;
        border-radius: 2px;
      }
    }

    &-sec {
      min-width: 160px;
      min-height: 100px;
      max-height: 500px;
      overflow: hidden;
      overflow-y: auto;
      background: #6c4ecb;
      box-shadow: 0px 0px 18px 0px rgba(94, 36, 239, 0.29);
      border-radius: 10px;
      position: relative;
      box-sizing: border-box;
      padding: 0 24px;

      .sec-item {
        box-sizing: border-box;
        padding: 32px 0;
        border-bottom: 1px solid rgba($color: #e8eaf3, $alpha: 0.13);
        flex-wrap: wrap;
        cursor: pointer;
        @include flexBox;

        &:last-child {
          border-bottom: none;
        }

        &--title {
          height: 2em;
          line-height: 2em;
          font-size: 16px;
          color: #a891e9;

          i {
            font-size: 12px;
          }
        }

        &.current,
        &:not(.current):hover {
          .sec-item--title {
            color: #fff;
          }
        }

        &.current {
          .sec-item--title i {
            transform: rotate(90deg);
          }
        }
      }
    }

    &-third {
      min-width: 100%;
      width: auto;
      max-height: 0;
      overflow: hidden;
      box-sizing: border-box;
      padding-left: 30px;
      transition-duration: 0;
      flex-shrink: 0;

      .third-item {
        &--title {
          height: 2.4em;
          line-height: 2.4em;
          font-size: 14px;
          color: #af9bee;

          i {
            width: 8px;
            height: 8px;
            position: relative;
            margin-left: 10px;

            &::before {
              content: "";
              width: 10px;
              height: 1px;
              background: #af9bee;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            &::after {
              content: "";
              width: 100%;
              height: 1px;
              background: #af9bee;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(90deg);
              @include defaultAni(0.3s);
            }
          }
        }

        &.current,
        &:not(.current):hover {
          .third-item--title {
            color: #fff;

            i::after {
              background: #fff;
            }
          }
        }

        &.current {
          .third-item--title i::after {
            transform: translate(-50%, -50%) rotate(180deg);
          }
        }
      }

      &.show {
        max-height: 100vh;
        transition-duration: 0;
      }
    }
  }

  .login-out {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #e6e4f3;
    margin: 4vh auto 0;
    cursor: pointer;
    font-size: 30px;
    color: #6c4ecb;
    @include flexBox(center);

    &:hover {
      color: #fff;
      background: #6c4ecb;
    }
  }

  .hide {
    display: none !important;
  }
}
</style>

<template>
  <section class="sidebar" ref="sidebar">
    <div
      class="user"
      @click="$router.push({ name: 'HOME_PERSONALCENTER' })"
      :title="userInfo.stuser_examinestatus == 20 ? userInfo.grade : ''"
    >
      <div class="avatar">
        <el-avatar
          fit="cover"
          :size="65"
          :src="formatfile(userInfo.stuser_image)"
        >
          <img src="@assets/images/empty_avatar.png" />
        </el-avatar>
        <div class="state" v-if="userInfo.stuser_examinestatus == 10">
          审核中
        </div>
        <div
          class="state fail"
          v-if="userInfo.stuser_examinestatus == 30"
          @click.stop="$router.push({name: 'UPDATA_AUDIT', params: {}})"
        >
          审核失败<br />修改信息
        </div>
      </div>
      <h5 class="bold line-text--1st">{{ userInfo.stuser_name }}</h5>
      <!-- <div class="title" v-if="userInfo.stuser_examinestatus==20">{{userInfo.grade}}</div> -->
    </div>
    <div class="swiper menu" ref="menu">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide menu-fir"
          :class="{ current: menuFirIndex == index, hide: item.hide }"
          v-for="(item, index) in menuList"
          :key="index"
          @mouseenter="mouseOver(index)"
        >
          <router-link
            class="title link"
            :to="{ name: item.routerName }"
            v-if="!item._children"
          >
            <div class="dian" v-if="chatNumber > 0 && item.title == '联系老师'">
              {{ chatNumber }}
            </div>
            <i v-html="item.icon" />
            <p>{{ item.title }}</p>
          </router-link>
          <template v-else>
            <div class="title">
              <div
                class="dian"
                v-if="chatNumber > 0 && item.title == '联系老师'"
              >
                {{ chatNumber }}
              </div>
              <i v-html="item.icon" />
              <p>{{ item.title }}</p>
            </div>
            <div
              class="menu-sec-box"
              @mouseenter="menuSecHover"
              @mouseleave="menuSecLeave"
            >
              <!-- 二级导航 -->
              <ul class="menu-sec">
                <li
                  class="sec-item"
                  v-for="(secitem, secindex) in item._children"
                  :key="secindex"
                  :class="{
                    current: menuSecIndex == secindex && menuFirIndex == index,
                    hide: secitem.hide,
                  }"
                >
                  <p
                    class="sec-item--title"
                    v-if="!secitem._children"
                    @click="routeTap(secitem, item)"
                  >
                    {{ secitem.title }}
                  </p>
                  <template v-else>
                    <p
                      class="sec-item--title"
                      @click.stop="checkMenuSec(secindex, index)"
                    >
                      <span>{{ secitem.title }}</span>
                      <i class="iconfont">&#xe637;</i>
                    </p>
                    <!-- 三级导航 -->
                    <ul
                      class="menu-third"
                      :class="{
                        show: menuFirIndex == index && menuSecIndex == secindex,
                      }"
                    >
                      <li
                        class="third-item"
                        :class="{
                          current:
                            menuThirdIndex == thirdindex &&
                            menuSecIndex == secindex &&
                            menuFirIndex == index,
                        }"
                        v-for="(thirditem, thirdindex) in secitem._children"
                        :key="thirdindex"
                        @click="
                          (menuThirdIndex = thirdindex),
                            routeTap(thirditem, secitem)
                        "
                      >
                        <p class="third-item--title">{{ thirditem.title }}</p>
                      </li>
                    </ul>
                  </template>
                </li>
              </ul>
            </div>
          </template>
        </div>
        <div class="swiper-slide menu-fir">
          <a class="title link" target="_blank" :href="dgi_value">
            <i class="iconfont">&#xe60b;</i>
            <p>操作手册</p>
          </a>
        </div>
        <div class="swiper-slide login-out" @click="loginOut">
          <i class="iconfont">&#xe629;</i>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { $get_manual } from "@api/common";
import { mapState } from "vuex";
import { formatFile } from "@utils";
import Swiper, { Mousewheel, FreeMode } from "swiper";
import "swiper/swiper.min.css";
import _ from "lodash"; // JavaScript 实用工具库

Swiper.use([Mousewheel, FreeMode]);

export default {
  name: "Sidebar",
  computed: {
    ...mapState("common", ["classList"]),
    ...mapState("user", ["userInfo"]),
    formatfile() {
      return function (url) {
        return formatFile(url);
      };
    },
  },
  watch: {
    $route: {
      handler(route) {
        this.menuFirIndex = null;
        this.menuSecIndex = null;
        this.menuThirdIndex = null;
        let list = route.matched;
        this.initActiveMenu(list);
      },
      deep: true,
      immediate: true,
    },
    classList: {
      handler(val) {
        if (!this.$isEmpty(val)) {
          this.classData = [...val];
        }
        this.menuList = this.mapMenu(this.menuList);
        this.$nextTick(() => {
          // 刷新菜单swiper
          this.initSwiper();
        });
      },
      immediate: true,
    },
  },
  data() {
    return {
      classData: [], // 班级数据
      menuList: [
        {
          title: "首页",
          icon: "&#xe609;",
          routerName: "HOME",
        },
        {
          title: "课程表",
          icon: "&#xe660;",
          routerName: "SCHOOLTIMETABLE",
        },
        {
          title: "校内课程",
          icon: "&#xe624;",
          routerName: "COURSE_INDEX",
        },
        {
          title: "作业考试",
          icon: "&#xe622;",
          routerName: "",
          _children: [
            {
              title: "考试",
              icon: "",
              routerName: "EXAM_INDEX",
            },
            {
              title: "校内作业",
              icon: "",
              routerName: "SCHOOL_JOB_INDEX",
            },
            {
              title: "家庭作业",
              icon: "",
              routerName: "JOB_INDEX",
            },
          ],
        },
        {
          title: "错题集",
          icon: "&#xe780;",
          routerName: "MISTAKES_INDEX",
        },
        {
          title: "严选课程",
          icon: "&#xe6c1;",
          routerName: "STRICT",
        },
        {
          title: "素质教育",
          icon: "&#xe65b;",
          routerName: "",
          _children: [
            {
              title: "素质课程",
              icon: "",
              routerName: "QUALITY_EDUCATION",
            },
            {
              title: "我的素质课程",
              icon: "",
              routerName: "QUALITY_MINE",
            },
          ],
        },
        {
          title: "校园动态",
          icon: "&#xe65f;",
          routerName: "SCHOOLNEWS",
        },
        {
          title: "联系老师",
          icon: "&#xe623;",
          routerName: "INTERACTION_MESSAGE",
        },
        {
          title: "荣誉证书",
          icon: "&#xe65a;",
          routerName: "HONOR",
        },
        {
          title: "意见反馈",
          icon: "&#xe65d;",
          routerName: "FEEDBACK",
        }
      ],
      menu_move: false, // 菜单是否正在滚动
      menuFirIndex: null,
      menuSecIndex: null,
      menuThirdIndex: null,
      menuFourthIndex: null,
      menuSwiper: null,
      index: 0,
      chatNumber: 0, //聊天未读数量
      //操作手册地址
      dgi_value: "",
    };
  },
  mounted() {
    if(this.userInfo.stuser_examinestatus == 30){
      this.$confirm('审核失败，请重新修改信息', '提示', {
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'warning'
        }).then(() => {
          this.$router.push({name: 'UPDATA_AUDIT', params: {}})
        })
    }
    this.get_manual();
    setTimeout(() => {
      this.getChatNumber();
    }, 1000);
  },
  methods: {
    /** 获取操作手册 */
    async get_manual() {
      let { data } = await $get_manual({ dgi_code: "STUDENT" });
      this.dgi_value = data.dgi_value;
    },
    /**
     * 导航栏遍历添加年/班级
     * @param {array} data 遍历数据节点
     */
    mapMenu(data) {
      data.map((item) => {
        if (item.chilrenIsClass && this.$isEmpty(this.classData)) {
          item.hide = true;
        } else {
          item.hide = false;
        }
      });
      data.forEach((item) => {
        if (item.chilrenIsClass && this.classData.length) {
          let classArr = [];
          this.classData.forEach((c) => {
            classArr.push({
              title: `${c.sccla_grade_name}${c.sccla_name}`,
              id: `${c.sccla_grade},${c.sccla_id}`,
              type: "class",
              icon: "",
              routerName: "",
            });
          });
          item._children = classArr;
        }
        if (item._children && item._children.length) {
          item._children = this.mapMenu(item._children);
        }
      });
      return data;
    },
    /** 导航栏swiper实例化 */
    initSwiper() {
      this.menuSwiper = new Swiper(".menu", {
        initialSlide: 0,
        direction: "vertical",
        slidesPerView: "auto",
        freeMode: true,
        mousewheel: true,
      });
    },
    /** 初始化导航栏高亮 */
    initActiveMenu(list) {
      list = this.$_.unionBy(list, "meta.title");
      let title = (list.length > 1 && list[1].meta.title.split(">")) || [""];
      /* 导航栏遍历 */
      this.menuList.map((item, index) => {
        /* 一级栏目 */
        if (item.title == list[0].meta.title) {
          this.menuFirIndex = index;
          /* 二级栏目遍历 */
          if (item._children) {
            item._children.map((secitem, secindex) => {
              /* 模块子级为班级 */
              if (secitem.chilrenIsClass) {
                if (secitem.title == title[0]) {
                  this.menuSecIndex = secindex;
                  /* 班级定位 */
                  if (secitem._children) {
                    secitem._children.map((thirditem, thirdindex) => {
                      if (thirditem.title == this.$route.params.class) {
                        this.menuThirdIndex = thirdindex;
                      }
                    });
                  }
                }
              } else {
                /* 其他模块 */
                if (
                  item.chilrenIsClass &&
                  secitem.title == this.$route.params.class
                ) {
                  this.menuSecIndex = secindex;
                } else {
                  /* 二级栏目 */
                  if (secitem.title == title[0]) {
                    this.menuSecIndex = secindex;
                    /* 三级栏目 */
                    if (secitem._children) {
                      secitem._children.map((thirditem, thirdindex) => {
                        if (thirditem.title == title[1]) {
                          this.menuThirdIndex = thirdindex;
                        }
                      });
                    }
                  }
                }
              }
            });
          }
        }
      });
    },
    /** 一级栏目鼠标移入 */
    mouseOver(index) {
      // let el = this.menuSwiper.slides[index];
      // let top = el.getBoundingClientRect().top;
      // const page_h = document.documentElement.clientHeight * 0.65;
      // if (this.menu_move) return;
      // if (index >= 4 && top >= page_h) {
      //   this.menuSwiper.slideTo(index, 800, false);
      //   this.menu_move = true;
      // } else if (index >= 4 && top < page_h) {
      //   this.menuSwiper.slideTo(index - 4, 800, false);
      //   this.menu_move = true;
      // } else if (index < 4 && top < page_h) {
      //   this.menuSwiper.slideTo(0, 800, false);
      //   this.menu_move = true;
      // }
      // setTimeout(() => {
      //   this.menu_move = false;
      // }, 850);
    },
    /** 二级栏目鼠标移入 */
    menuSecHover() {
      this.menuSwiper.mousewheel.disable();
    },
    /** 二级栏目鼠标移出 */
    menuSecLeave() {
      this.menuSwiper.mousewheel.enable();
      this.menuFirIndex = null;
      this.menuSecIndex = null;
      this.menuThirdIndex = null;
      this.initActiveMenu(this.$route.matched);
    },
    checkMenuSec(sec, fir) {
      this.menuFirIndex = fir;
      this.menuSecIndex = sec;
    },
    /** 栏目跳转 */
    routeTap(data, parentData) {
      if (data.type) {
        this.$router.push({
          name: parentData.routerName,
          params: { class: data.title, id: data.id },
        });
      } else {
        this.$router.push({
          name: data.routerName,
        });
      }
    },
    /** 退出登录 */
    loginOut() {
      sessionStorage.clear();
      this.$router.replace({ name: "LOGIN" });
    },
    //获取聊天数量
    getChatNumber() {
      let _this = this
      if (sessionStorage.getItem("imIsSDKReady")) {
        this.chatNumber = this.tim.getTotalUnreadMessageCount()
        //及时获取新消息数量
        this.tim.on(this.TIM.EVENT.TOTAL_UNREAD_MESSAGE_COUNT_UPDATED, (event) => {
          _this.chatNumber = event.data
        }, _this);
      }
    },
  },
};
</script>
