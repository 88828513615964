/* *********************** 正式环境 ********************** */
// export const API_ROOT = "";

/* *********************** 测试环境 ********************** */
export const API_ROOT = "https://api.zytycd.com";
// export const API_ROOT = "https://api.zytycd.com";

/* *********************** 开发环境 ********************** */
// export const API_ROOT = "/api";
// export const API_ROOT = "/gq_api"; // gq
// export const API_ROOT = "/wyp_api"; // wyp
// export const API_ROOT = "/api";
// 系统标题
export const TITLE = "智源通育材地智能教学生态系统";

// 生产环境和测试环境
// export const DEBUG = process.env.NODE_ENV !== "production";
export const DEBUG = true;

// 设置请求的超时时间   单位毫秒
export const REQUEST_TIMEOUT = 30000;

// 文件服务器地址
// export const FILE_ROOT = "http://192.168.2.123:1026"; // gq
// export const FILE_ROOT = "http://192.168.2.200:1030"; // wyp
export const FILE_ROOT = "https://yucaidi.oss-cn-chengdu.aliyuncs.com/";

// 一页显示的条目
export const PAGE_SIZE = 10;
